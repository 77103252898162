<template>
  <div style="width:100%;float:left;">
    <admin-dashboard v-if="$store.state.user && $store.state.user.roles.indexOf('admin') > -1"></admin-dashboard>
    <csr-dashboard v-else-if="$store.state.user && $store.state.user.user_type === 'in_house'"></csr-dashboard>
    <customer-dashboard v-else-if="$store.state.user"></customer-dashboard>
    <small-loader v-else></small-loader>
    <!-- <div v-else style="width:100%;float:left;">
      <CCard>
        <CCardBody>
          <CButton size="md" color="success" @click="$router.push('/login')">Click here to login.</CButton>
        </CCardBody>
      </CCard>
    </div> -->
  </div>
</template>

<script>
import AdminDashboard from './dashboards/admin_dashboard';
import CsrDashboard from './dashboards/csr_dashboard';
import CustomerDashboard from './dashboards/customer_dashboard';
var methods = {}

export default {
  name: 'Dashboard',
  components: {
    AdminDashboard,
    CsrDashboard,
    CustomerDashboard
  },
  data () {
    return {

    }
  },
  methods: methods
}
</script>
