<template>
    <div style="width:100%;float:left;">
        <my-orders></my-orders>
    </div>
</template>
<script>
import MyOrders from './../orders/my_orders' 
var methods = {}

export default {
    // ===Component name
    name: "CustomerDashboard",
    // ===Props passed to component
    props: {},
    // ===Components used by this component
    components: {
        MyOrders
    },
    // ====component Data properties
    data(){
        return{

        }
    },
    // ===Code to be executed when Component is mounted
    mounted() {

    },
    // ===Computed properties for the component
    computed: {},
    // ===Component methods
    methods: {

    }
}
</script>
<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style scoped></style>
