<template>
    <div style="width:100%;float:left;">
        <customer-orders></customer-orders>
    </div>
</template>
<script>
import CustomerOrders from './../orders/customer_orders'
var methods = {}

export default {
    // ===Component name
    name: "AdminDashboard",
    // ===Props passed to component
    props: {},
    // ===Components used by this component
    components: {
        CustomerOrders
    },
    // ====component Data properties
    data(){
        return{

        }
    },
    // ===Code to be executed when Component is mounted
    mounted() {

    },
    // ===Computed properties for the component
    computed: {},
    // ===Component methods
    methods: {

    }
}
</script>
<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style scoped></style>
